import React from 'react';
import './Header.css'; // Create a separate CSS file for styling

const Header = () => {
  // Function to decode and open the phone number in the dialer app
  const decodePhone = () => {
    const parts = ["516", "557", "7096"];
    const phone = "tel:" + parts.join(""); // Dynamically join parts
    window.location.href = phone; // Opens the dialer app with the number
  };

  // Function to decode and open the email in the default email app
  const decodeEmail = () => {
    const user = "flrc";
    const domain = "aol.com";
    const email = `mailto:${user}@${domain}`; // Dynamically construct the email
    window.location.href = email; // Opens the default email app with the address
  };

  return (
    <header className="sticky-header">
      <div className="header-content">
        <div className="coin-title">
          <img src={`${process.env.PUBLIC_URL}/images/MelvilleCoin.webp`} className="header-image" alt="Coin Logo" />
          <h1 className="show-title">Melville Coin, Stamp, & Collectibles Show</h1>
        </div>
        <div className="contact">
          <div className="call">
            Call:&nbsp;
            <button
              onClick={decodePhone}
              className='call-button'
              aria-label="Call (516) 557-7096"
            >
              (516) 557-7096
            </button>
          </div>
          <div className="email">
            Email:&nbsp;
            <button
              onClick={decodeEmail}
              className='email-button'
              aria-label="Email FLRC@AOL.COM"
            >
              FLRC@AOL.COM
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
