import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import Calendar from './Calendar';
import Header from './Header';
import BackgroundSlider from './BackgroundSlider';
import Location from './Location';
import GoogleMap from './GoogleMap';

function App() {
  const [currentDay, setCurrentDay] = useState('');
  const [googleMapVisible, setGoogleMapVisible] = useState(false);
  const [locationVisible, setLocationVisible] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);
  const googleMapRef = useRef(null);
  const locationRef = useRef(null);
  const calendarRef = useRef(null);

  useEffect(() => {

    const today = new Date();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    setCurrentDay(today.toLocaleDateString(undefined, options));

    const observerOptions = {
      threshold: 0.1,
    };

    const googleMapObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setGoogleMapVisible(true);
        }
      });
    }, observerOptions);

    const locationObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setLocationVisible(true);
        }
      });
    }, observerOptions);

    const calendarObserver = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setFadeIn(true);
        }
      });
    }, observerOptions);

    // Copying the current ref values to variables
    const googleMap = googleMapRef.current;
    const location = locationRef.current;
    const calendar = calendarRef.current;

    if (googleMap) {
      googleMapObserver.observe(googleMap);
    }
    if (location) {
      locationObserver.observe(location);
    }
    if (calendar) {
      calendarObserver.observe(calendar);
    }

    // Cleanup the observers on component unmount
    return () => {
      if (googleMap) googleMapObserver.unobserve(googleMap);
      if (location) locationObserver.unobserve(location);
      if (calendar) calendarObserver.unobserve(calendar);
    };
  }, []); // You can add any relevant dependencies here if needed

  return (
    <div className="App" style={{backgroundColor : "whitesmoke"}}>
      <Header />
      <BackgroundSlider />
      <div 
        className={`googleMap-container ${googleMapVisible ? 'animate-slideInLeft' : ''}`} 
        ref={googleMapRef}
      >
        <div className="text-section">
          <h1>Come and Visit!</h1>
          <p>Serving collectors for over <strong>60 Years!</strong></p>
          <p>We are located on Broadhollow Rd, about a half-mile south from LIE Exit 49.</p>
          <p>Address: 535 Broadhollow Rd, Melville, NY 11747</p>
          <p>Hours: 8am-1pm</p>
        </div>
        <div className="googleMap">
          <GoogleMap />
        </div>
      </div>

      <div 
        className={`location-container ${locationVisible ? 'animate-slideInRight' : ''}`} 
        ref={locationRef}
      >
        <div className="location-image">
          <Location />
        </div>
        <div className="location-text-section">
          <h1>Everyone Welcome!!</h1>
          <p>No registration is necessary.</p>
          <p>Free parking and admission.</p>
          <p>Entrance is located on <strong>South</strong> side of the building.</p>
          <p><strong>Contact</strong> us above with any inquiries or further assistance.</p>
        </div>
      </div>

      <div className={`fade-in ${fadeIn ? 'visible' : ''}`} ref={calendarRef}>
        <div className="calendar-container">
          <h1 className="calendar-day">Today is: {currentDay}</h1>
          <h1 className="calendar-title"> All Show Dates Highlighted in Red Below</h1>
          <Calendar />
        </div>
      </div>

      <div className="pdf-link">
        <a href={`${process.env.PUBLIC_URL}/images/melville flyers 2025.pdf`} target="_blank" rel="noopener noreferrer">
        <img src={`${process.env.PUBLIC_URL}/images/melville-flyer-2025.webp`} alt="PDF Icon" className="pdf-icon" />
        <h3>Download Our Flyer</h3>
        </a>
      </div>
      <div className="ntcbanner">
        <a href="http://www.NTCCOIN.com" target="_blank" rel="noopener noreferrer">
          <img 
            src={`${process.env.PUBLIC_URL}/images/NTCbanner.webp`} 
            alt="NTCBanner" 
            className="ntcbanner-image"
          />
        </a>
      </div>
    </div>
  );
}

export default App;
